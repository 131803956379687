import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 26 34">
    <path
      fill={color}
      d="M20.9,16.7c-1,0-1.7-0.8-1.7-1.8c0-1,0.7-1.8,1.7-1.8c1,0,1.7,0.8,1.7,1.8C22.7,15.9,21.9,16.7,20.9,16.7z M16.2,16.7
	c-1,0-1.7-0.8-1.7-1.8c0-1,0.7-1.8,1.7-1.8c1,0,1.7,0.8,1.7,1.8C17.9,15.9,17.2,16.7,16.2,16.7z M11.4,16.7c-1,0-1.7-0.8-1.7-1.8
	c0-1,0.7-1.8,1.7-1.8c1,0,1.7,0.8,1.7,1.8C13.1,15.9,12.4,16.7,11.4,16.7z M23.6,7.7H8.7c-1.4,0-2.3,1.1-2.3,2.4v9.3
	c0,1.3,1.1,2.4,2.3,2.4h0.2V24c0,1,0.6,1.8,1.5,2.1c0.2,0.1,0.5,0.3,0.9,0.3c0.6,0,1.2-0.3,1.6-0.6l4.1-3.9h6.8
	c1.2,0,2.3-1.1,2.3-2.4V10C25.9,8.8,25,7.7,23.6,7.7z M13.2,1.9H6.6c-0.1,0-0.2-0.1-0.2-0.3s0.1-0.3,0.2-0.3h6.7
	c0.1,0,0.2,0.1,0.2,0.3S13.4,1.9,13.2,1.9z M7.3,30.6h5.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H7.3c-0.5,0-0.9-0.4-0.9-0.9
	C6.5,31,6.8,30.6,7.3,30.6z M20,5.3V2.5C20,1.1,19,0,17.7,0H2.3C0.9,0-0.1,1.1-0.1,2.5v29c0,1.4,1,2.5,2.3,2.5h15.4
	c1.2,0,2.3-1.1,2.3-2.5v-7.3h-2V29H1.9V3.8h16.1v1.4h2V5.3z"
    />
  </svg>
);
